.view-bracket-header {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    background-color: lightgray;
    position: fixed;
    width: 100%;
    height: 45px;
    z-index: 1;
}

.view-bracket-header.placeholder {
    position: unset;
    background-color: unset;
    height: 45px; /* unclear why this is necessary */
}

.view-bracket-header > .score, .view-bracket-header > .progress {
    justify-self: right;
}

.progress {
    width: 100px;
    display: flex;
    flex-direction: column;
    margin: 4px;
    margin-right: 20px;
}

.progress-bar {
    width: 100%;
    height: 20px;
    background-color: lightgray;
    border: 1px solid black;
}

.progress-bar-inner {
    background-color: lightgreen;
    height: 100%;
}

.picks-count {
    align-self: center;
}

.hidden {
    display: none !important;
}

.bracket.finalFour .finals .nw-team {
    grid-row: 1;
    grid-column: 1;
}

.bracket.entireBracket .finals .nw-team,
.bracket.entireBracket .finals .sw-team,
.bracket.entireBracket .finals .ne-team,
.bracket.entireBracket .finals .se-team {
    display: none;
}

.bracket.finalFour {
    margin: 4em;
}

.bracket.finalFour .finals .nw-team {
    grid-row: 1;
    grid-column: 1;
    justify-self: right;
}

.bracket.finalFour .finals .sw-team {
    grid-row: 5;
    grid-column: 1;
    justify-self: right;
}

.bracket.finalFour .finals .ne-team {
    grid-row: 5;
    grid-column: 5;
}

.bracket.finalFour .finals .se-team {
    grid-row: 9;
    grid-column: 5;
}

.bracket.finalFour .finals .game-0 {
    grid-row: 3;
    grid-column: 2;
}

.bracket.finalFour .finals .game-1 {
    grid-row: 7;
    grid-column: 4;
}

.bracket.finalFour .finals .game-2 {
    grid-row: 5;
    grid-column: 3;
}


.finalFour .line-nw-to-game-60 {
    grid-column: 1;
    grid-row: 2;
    width: 100%;
    height: 100%;
    border-right: 1px solid black;
}

.finalFour .line-sw-to-game-60 {
    grid-column: 1;
    grid-row: 4;
    width: 100%;
    height: 100%;
    border-right: 1px solid black;
}

.finalFour .line-game-60-to-game-62 {
    grid-column: 2;
    grid-row: 4;
    width: 100%;
    height: 100%;
    border-right: 1px solid black;
}

.finalFour .line-ne-to-game-61 {
    grid-column: 4;
    grid-row: 6;
    width: 100%;
    height: 100%;
    border-right: 1px solid black;
}

.finalFour .line-se-to-game-61 {
    grid-column: 4;
    grid-row: 8;
    width: 100%;
    height: 100%;
    border-right: 1px solid black;
}

.finalFour .line-game-61-to-game-62 {
    grid-column: 3;
    grid-row: 6;
    width: 100%;
    height: 100%;
    border-right: 1px solid black;
}

.region-chooser {
    position: fixed;
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: white; /* why is this necessary */
}

.region-chooser > button {
    width: 100px;
    margin: 0 10px;
    border: none;
    background-color: none;
}

.region-chooser > button.selected {
    background-color: lightgray;
}

.region-chooser.placeholder {
    position: unset;
    height: 30px;
    background-color: none;
}

.completed-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #FFFFFFAA;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.completed-overlay-content {
    background-color: white;
    padding: 20px;
    border: 1px solid black;
    border-radius: 5px;
    display: flex;
    align-items: left;
    width: 400px;
    height: 400px;
    flex-direction: column;
    gap: 1em;
}

.completed-overlay-content > div:first-child {
    align-self: center;
    font-weight: 500;
}

.completed-overlay button {
    width: max-content;
}

.rules {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin: 1em;
}

.entered-pool-info > div:first-child {
    font-weight: 500;
    text-align: center;
    font-size: larger;
    margin: 1em;
}

.entered-pool-info > div:nth-child(2) {
    font-weight: 500;
    text-decoration: underline;
}
  
@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@property --param {
    syntax: '<number>'; /* <- defined as type number for the transition to work */
    initial-value: 1;
    inherits: false;
}
  
@keyframes fadeIn {
    50% {--param: 1}
    100% {--param: 0}
}

@property --time {
    syntax: '<number>'; /* <- defined as type number for the transition to work */
    initial-value: 0;
    inherits: false;
}
  
@keyframes shift {
    100% {--time: calc(2 * 3.14159)}
}

.loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

img.rotate {
    width: 300px;
    animation: rotation 6s linear infinite reverse;
}

img.orbit {
    width: 100px;
    position: absolute;
    animation: shift 4s linear infinite;
}

img.orbit.first {
    transform: translate(calc(300px * cos(var(--time))), calc(300px * sin(var(--time))));
    opacity: var(--param);
}

img.orbit.second {
    transform: translate(calc(300px * cos(calc(var(--time) + 2 * 3.14 / 3))), calc(300px * sin(calc(var(--time) + 2 * 3.14 / 3))));
    opacity: var(--param);
}

img.orbit.third {
    transform: translate(calc(300px * cos(calc(var(--time) + 4 * 3.14 / 3))), calc(300px * sin(calc(var(--time) + 4 * 3.14 / 3))));
    opacity: var(--param);
}

.timed-out-message {
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    text-align: center;
}