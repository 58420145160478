.bracket {
    display: grid;
    grid-auto-columns: max-content;
}

/* is there a better way to center the bracket when viewing partial brackets? */
.bracket-holder:not(.entireBracket) {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: fit-content;
    width: 100%;
}

.view-bracket {
    transform-origin: left top;
}

.info {
    grid-column: 2;
    grid-row: 1;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    margin: 2em;
}

.nw-region {
    grid-column: 1;
    grid-row: 1;
    justify-self: right
}

.sw-region {
    grid-column: 1;
    grid-row: 3;
    justify-self: right
}

.ne-region {
    grid-column: 3;
    grid-row: 1;
    justify-self: left;
}

.se-region {
    grid-column: 3;
    grid-row: 3;
    justify-self: left
}

.nw-region::before {
    border-top: 1px solid black;
    background-color: black;
    height: 0px;
    width: 100%;
    grid-column: 5;
    grid-row: 8;
    align-self: end;
    justify-self: end;
    content: '';
}
.entireBracket .nw-region::after {
    border-left: 1px solid black;
    background-color: black;
    height: 100%;
    width: 0px;
    grid-column: 5;
    grid-row: 9 / 19;
    align-self: end;
    justify-self: end;
    content: '';
}

.sw-region::before {
    border-top: 1px solid black;
    background-color: black;
    height: 0px;
    width: 100%;
    grid-column: 5;
    grid-row: 8;
    align-self: end;
    justify-self: end;
    content: '';
}
.entireBracket .sw-region::after {
    border-left: 1px solid black;
    background-color: black;
    height: 100%;
    width: 0px;
    grid-column: 5;
    grid-row: 1 / 9;
    align-self: end;
    justify-self: end;
    content: '';
}

.ne-region::before {
    border-top: 1px solid black;
    background-color: black;
    height: 0px;
    width: 100%;
    grid-column: 5;
    grid-row: 8;
    align-self: end;
    justify-self: end;
    content: '';
}
.entireBracket .ne-region::after {
    border-left: 1px solid black;
    background-color: black;
    height: 100%;
    width: 0px;
    grid-column: 5;
    grid-row: 9 / 17;
    align-self: end;
    justify-self: end;
    content: '';
}

.se-region::before {
    border-top: 1px solid black;
    background-color: black;
    height: 0px;
    width: 100%;
    grid-column: 5;
    grid-row: 8;
    align-self: end;
    justify-self: end;
    content: '';
}
.entireBracket .se-region::after {
    border-left: 1px solid black;
    background-color: black;
    height: 100%;
    width: 0px;
    grid-column: 5;
    grid-row: 1 / 9;
    align-self: end;
    justify-self: end;
    content: '';
}

/* add some margin when just viewing a single region */
.region:not(.entireBracket .region) {
    margin-top: 4em;
}

.bracket.region1 .nw-region {
    grid-column: 1;
    grid-row: 1;
    justify-self: right;
}
.bracket.region1 .sw-region,
.bracket.region1 .ne-region,
.bracket.region1 .se-region,
.bracket.region1 .finals {
    display: none;
}

.bracket.region2 .sw-region {
    grid-column: 1;
    grid-row: 1;
    justify-self: right;
}
.bracket.region2 .nw-region,
.bracket.region2 .ne-region,
.bracket.region2 .se-region,
.bracket.region2 .finals {
    display: none;
}

.bracket.region3 .ne-region {
    grid-row: 1;
    grid-column: 1;
    direction: unset !important;
}
.bracket.region3 .nw-region,
.bracket.region3 .sw-region,
.bracket.region3 .se-region,
.bracket.region3 .finals {
    display: none;
}

.bracket.region4 .se-region {
    grid-row: 1;
    grid-column: 1;
    direction: unset !important;
}
.bracket.region4 .nw-region,
.bracket.region4 .sw-region,
.bracket.region4 .ne-region,
.bracket.region4 .finals {
    display: none;
}

.bracket.region3 .finalFour {
    grid-row: 1;
    grid-column: 1;
}
.bracket.finalFour .nw-region,
.bracket.finalFour .sw-region,
.bracket.finalFour .se-region,
.bracket.finalFour .ne-region {
    display: none;
}

.region .title {
    grid-column: 1  / 5;
    text-align: center;
    font-weight: 500
}