.enter-pool-container {
    display: grid;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.enter-pool-container > div {
    grid-column: 1 / 3;
}

.enter-pool-container > * {
    margin: 4px;
}

.error {
    color: red;
}