.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #282c34;
  color: white;
  height: 30px;
  position: fixed;
  margin: 0 auto;
  width: 100%;
}
.placeholder { /* to move everything down */
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin: 0 auto;
  width: 100%;
}

.home-main {
  display: grid;
  grid-gap: 1em;
  justify-items: center;
  align-content: start;
  margin-bottom: 10px;
  min-height: 300px;
}

.announcement {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  margin-top: 15px;
  padding: 8px;
  border: 1px solid black;
  background-color: lightgray;
  gap: 5px;
}

.message-board-posting {
  display: grid;
  align-items: center;
  justify-items: center;
}

form {
  display: grid;
  grid-template-columns: 100px 1fr;
  width: 400px;
}

form > label {
  justify-self: end;
  margin-right: 8px;
}

form > input, textarea {
  justify-self: end;
  width: 100%;
  margin: 1px;
  padding: 2px;
  border: 0px;
  border: 1px solid black;
}

form > input[type=submit] {
  grid-column: 2;
  width: 75px;
}

form > textarea {
  height: 200px;
  resize: none;
}

.message-board-header {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
}

.message-board-header button {
  height: max-content;
  width: max-content;
  background: none;
  /* border: none; */
  font-size: large;
  margin-left: 50px; /* would be better to have one large container that had the right width and then align this to the right */
}

.message-board-messages {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.message-board-messages.no-messages > div {
  text-align: center;
  justify-content: center;
  align-content: center;
}

.message-bars {
  justify-content: center;
}

/* message and divider */
.message-board-messages > div {
  width: 75%;
  max-width: 500px;
}

.message {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
}

.message > h2 {
  margin-bottom: 10px;
}

.message > div.message-date {
  justify-self: right;
}

.message > div.message-text {
  white-space: pre-line;
  grid-column: 1 / 3;
}

.navbar a {
  color: white;
  text-decoration: none;
  margin: 0 1em;
}

.navbar a:hover {
  color: lightgray;
}

.navbar a.active {
  color: lightgray;
}

.region {
  display:grid;
  grid-template-rows:repeat(16, 30px);
  grid-auto-columns:180px; /* can be adjusted if team names are too long */
  grid-auto-flow:column;
  align-items: center;
}

.right-side {
  direction: rtl;
}

.game {
  margin: 0.25em;
  border: solid 1px black;
  text-align: right;
  background-color: white;
  overflow: visible;
}

.game:hover {
  background-color: lightgray;
}

.dead {
  background-color: brown;
}

.correct {
  background-color: yellow;
}

.team {
  text-align: right;
  border: solid 1px black;
  padding-right: 2px;
}

.right-side > .team, .game {
  text-align: left;
}

.divider {
  border: solid 0.5px black;
  margin: 4px;
  align-content: center;
  align-self: center;
  justify-self: end;
  z-index: -1;
}

.divider.vertical {
  width: 0px;
  height: 100%;
}

.divider.horizontal {
  width: 100%;
  height: 0px;
}

.d-1 {
  grid-row: 2 / 4;
}
.d-1.vertical {
  grid-column: 2;
}
.d-1.horizontal {
  grid-column: 3;
}

.d-2 {
  grid-row: 6 / 8;
}
.d-2.vertical {
  grid-column: 2;
}
.d-2.horizontal {
  grid-column: 3;
}

.d-3 {
  grid-row: 10 / 12;
}
.d-3.vertical {
  grid-column: 2;
}
.d-3.horizontal {
  grid-column: 3;
}

.d-4 {
  grid-row: 14 / 16;
}
.d-4.vertical {
  grid-column: 2;
}
.d-4.horizontal {
  grid-column: 3;
}

.d-5 {
  grid-row: 3 / 7;
}
.d-5.vertical {
  grid-column: 3;
}
.d-5.horizontal {
  grid-column: 4;
}

.d-6 {
  grid-row: 11 / 15;
}
.d-6.vertical {
  grid-column: 3;
}
.d-6.horizontal {
  grid-column: 4;
}

.d-7 {
  grid-row: 5 / 13;
}
.d-7.vertical {
  grid-column: 4;
}
.d-7.horizontal {
  grid-column: 5;
}

.team-even {
  align-self: end;
  margin-bottom: 1.5px;
}

.team-odd {
  align-self: baseline;
  margin-top: 1.5px;
}

.team-2 {
  align-self: end;
  margin-bottom: 1.5px;
}

.team-3 {
  align-self: baseline;
  margin-top: 1.5px;
}

.game-0 {
  grid-column: 2;
  grid-row: 1 / 3;
}

.game-1 {
  grid-column: 2;
  grid-row: 3 / 5;
}

.game-2 {
  grid-column: 2;
  grid-row: 5 / 7;
}

.game-3 {
  grid-column: 2;
  grid-row: 7 / 9;
}

.game-4 {
  grid-column: 2;
  grid-row: 9 / 11;
}

.game-5 {
  grid-column: 2;
  grid-row: 11 / 13;
}

.game-6 {
  grid-column: 2;
  grid-row: 13 / 15;
}

.game-7 {
  grid-column: 2;
  grid-row: 15 / 17;
}

/* games start with 0 */
/* 2nd column, games 8-11 */

.game-8 {
  grid-column: 3;
  grid-row: 2 / 4;
}

.game-9 {
  grid-column: 3;
  grid-row: 6 / 8;
}

.game-10 {
  grid-column: 3;
  grid-row: 10 / 12;
}

.game-11 {
  grid-column: 3;
  grid-row: 14 / 16;
}

/* 3rd column, games 12 & 13 */

.game-12 {
  grid-column: 4;
  grid-row: 1 / 9;
}

.game-13 {
  grid-column: 4;
  grid-row: 9 / 18;
}

/* 4 column, game 14 */

.game-14 {
  grid-column: 5;
  grid-row: 1 / 17;
}

.finals {
  grid-column: 2;
  grid-row: 2;
  display: grid;
  grid-auto-columns: 150px;
  grid-auto-rows: 18px;
  align-items: center;
}

.finals > .game-0 {
  grid-column: 1;
  grid-row: 1;
  text-align: right;
}

.finals > .game-1 {
  grid-column: 3;
  grid-row: 3;
}

.finals > .game-2 {
  grid-column: 2;
  grid-row: 2;
  text-align: center;
  padding: 0em 1em;
}

.finals > .champion {
  grid-column: 2;
  grid-row: 3;
  text-align: center;
  font-weight: 500;
  padding-top: 2px;
}

.finals > .title {
  display: none;
}

.bracket.finalFour .finals .title {
  grid-column: 3;
  grid-row: 11;
  text-align: center;
  display: unset;
}

.bracket.finalFour .finals > .champion {
  grid-column: 3;
  grid-row: 6;
  text-align: center;
}

/*
.divider-sw-h {
  grid-column: 1;
  grid-row: 4 / 6;
  height: 0;
  width: 25%;
  border-bottom: 1px solid black;
  align-self: end;
  justify-self: baseline;
  content: '';
}
*/
.entireBracket .divider-sw-v {
  height: 300%;
  width: 1px;
  background-color: black;
  position: relative;
  content: '';
  left: -151px;
  top: 90px;
}

h2 {
  margin: 0;
  padding: 0;
}