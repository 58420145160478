.game-0 {
    grid-column: 6;
    grid-row: 1 / 9;
}

.game-1 {
    grid-column: 5;
    grid-row: 1 / 5;
}

.game-2 {
    grid-column: 5;
    grid-row: 5 / 9;
}

.game-3 {
    grid-column: 4;
    grid-row: 1 / 3;
}

.game-4 {
    grid-column: 4;
    grid-row: 3 / 5;
}

.game-5 {
    grid-column: 4;
    grid-row: 5 / 7;
}

.game-6 {
    grid-column: 4;
    grid-row: 7 / 9;
}

.game-7 {
    grid-column: 3;
    grid-row: 1;
}

.game-8 {
    grid-column: 2;
    grid-row: 1;
}

.game-9 {
    grid-column: 3;
    grid-row: 7;
}

.team-0 {
    grid-column: 3;
    grid-row: 2;
}

.team-1 {
    grid-column: 3;
    grid-row: 3;
}

.team-2 {
    grid-column: 3;
    grid-row: 4;
}

.team-3 {
    grid-column: 3;
    grid-row: 5;
}

.team-4 {
    grid-column: 3;
    grid-row: 6;
}

.team-5 {
    grid-column: 3;
    grid-row: 8;
}

.team-8 {
    grid-column: 1;
    grid-row: 7;
}

.team-9 {
    grid-column: 1;
    grid-row: 8;
}